<template>
  <header id="main-header" class="header">
    <nav class="navbar navbar-expand-xl p-0">
      <div class="w-100">
        <div class="d-grid align-items-center header-first w-100 primary-color-background">
          <div class="d-flex justify-content-evenly">
            <div class="d-none d-md-flex align-items-center justify-content-start">
              <div id="switcher" class="switch__input d-none" :class="{'checked': switchRoute}" />
              <div class="slider position-relative d-inline-block order-2 flex-shrink-0" @click="switchRouteHandler()" />
              <h3 class="order-1 switch__label cursor-pointer text-center gold-text" @click="switchRouteHandler()">{{ $t('basic.gold') }}</h3>
              <span class="order-3 switch__checked cursor-pointer text-center silver-text" @click="switchRouteHandler()">{{ $t('basic.silver') }}</span>
            </div>
            <button
              class="navbar-toggler p-0 shadow-none burger-menu-media"
              type="button"
              @click="toggleMenu"
            >
              <img src="~/assets/images/menu-burger.svg" width="22" height="11" alt="menu" />
            </button>
          </div>
          <div
            class="navbar-brand align-self-center cursor-pointer justify-content-center d-flex"
            @click="navigateToMain"
          >
            <img src="~/assets/images/goldcenter-logo.svg" class="navbar-brand__image logo-heigth" alt="hart icon" />
          </div>
          <ul
            class="navbar-nav flex-row p-0 align-items-center justify-content-end"
          >
            <li class="nav-item">
              <div
                class="nav-link p-0 d-flex align-items-center cursor-pointer"
                @click.prevent="goToWishlist"
              >
                <span class="d-none d-xl-block text-truncate text-white">
                  <span>{{ $t('menu.wishlist') }}</span>
                </span>
                <div class="position-relative">
                  <img src="~/assets/images/hart-header.svg" class="menyu-mobile-icon" alt="hart icon" />

                  <client-only>
                    <div class="notification-active">
                      {{ totalWishlist }}
                    </div>
                  </client-only>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <button
                class="btn nav-link p-0 d-flex align-items-center shadow-none"
                type="button"
                @click="$store.commit('ui/setShowBasket', true)"
              >
                <span class="d-none d-xl-block text-white"> {{ $t('menu.cart') }}</span>
                <div class="position-relative">
                  <img src="~/assets/images/basket-header.svg" class="menyu-mobile-icon" alt="basket icon" />

                  <client-only>
                    <div class="notification-active">
                      {{ totalCount }}
                    </div>
                  </client-only>
                </div>
              </button>
            </li>

            <li class="nav-item">
              <div
                class="nav-link p-0 d-flex align-items-center cursor-pointer position-relative"
                @click="openAuthModal"
              >
                <template v-if="!currentUser">
                  <span class="d-none d-xl-block text-white">
                    {{ $t('menu.login') }}
                  </span>

                  <img src="~/assets/images/user-header.svg" class="menyu-mobile-icon" alt="user icon" />
                </template>

                <div
                  v-else
                  class="show-dropdown-item d-flex align-items-center"
                >
                  <span class="d-none d-xl-block text-white">
                    {{ currentUserFullName }}
                  </span>
                  <svg
                    class="menyu-mobile-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                      stroke="white"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M2.90527 20.2491C3.82736 18.6531 5.15322 17.3278 6.74966 16.4064C8.34611 15.485 10.1569 15 12.0002 15C13.8434 15 15.6542 15.4851 17.2506 16.4065C18.8471 17.3279 20.1729 18.6533 21.0949 20.2493"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="current-user-menu" @click.stop>
                    <nuxt-link
                      v-if="isBuyer"
                      tag="div"
                      :to="localePath('/buyer-account')"
                      class="custom-dropdown-item ellipsis-mode"
                    >
                      <svg
                        class="menyu-mobile-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                          stroke="black"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M2.90527 20.2491C3.82736 18.6531 5.15322 17.3278 6.74966 16.4064C8.34611 15.485 10.1569 15 12.0002 15C13.8434 15 15.6542 15.4851 17.2506 16.4065C18.8471 17.3279 20.1729 18.6533 21.0949 20.2493"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {{ $t('basic.buyer_account') }}
                    </nuxt-link>
                    <div
                      v-else
                      class="custom-dropdown-item ellipsis-mode"
                      @click.stop="goToAdminPanel(currentUser.role_id !== 4)"
                    >
                      <svg
                        class="menyu-mobile-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                          stroke="black"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M2.90527 20.2491C3.82736 18.6531 5.15322 17.3278 6.74966 16.4064C8.34611 15.485 10.1569 15 12.0002 15C13.8434 15 15.6542 15.4851 17.2506 16.4065C18.8471 17.3279 20.1729 18.6533 21.0949 20.2493"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {{ currentUserFullName }}
                    </div>
                    <div
                      class="ellipsis-mode text-decoration-none aside__item d-flex align-items-center position-relative custom-dropdown-item"
                      @click.stop="logout"
                    >
                      <svg
                        class="aside__item__icon menyu-mobile-icon"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="black"
                          d="M3.86364 7.75H10.0455V9.25H3.86364V11.5L0 8.5L3.86364 5.5V7.75ZM3.09091 13H5.18345C6.07571 13.7638 7.17607 14.2614 8.35251 14.4332C9.52894 14.605 10.7315 14.4437 11.8158 13.9686C12.9001 13.4935 13.8201 12.7249 14.4655 11.7549C15.1109 10.7848 15.4542 9.65468 15.4542 8.5C15.4542 7.34531 15.1109 6.21516 14.4655 5.24514C13.8201 4.27512 12.9001 3.50646 11.8158 3.03137C10.7315 2.55629 9.52894 2.39498 8.35251 2.5668C7.17607 2.73861 6.07571 3.23625 5.18345 4H3.09091C3.81006 3.06788 4.74326 2.31138 5.81637 1.79061C6.88949 1.26983 8.07295 0.999127 9.27273 1C13.5405 1 17 4.35775 17 8.5C17 12.6422 13.5405 16 9.27273 16C8.07295 16.0009 6.88949 15.7302 5.81637 15.2094C4.74326 14.6886 3.81006 13.9321 3.09091 13Z"
                          class="aside__item__icon__fill"
                        />
                      </svg>

                      <span class="aside__item__text text-uppercase">
                        {{ $t('basic.log_out') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown d-none d-md-flex" v-click-outside="hideDeskLng">
              <a
                class="nav-link p-0 d-flex align-items-center dropdown-toggle text-uppercase text-white"
                role="button"
                @click="isLng = !isLng"
              >
                {{ selectedLanguage.name }}
              </a>
              <app-transition-expat>
                <ul v-show="isLng" class="position-absolute header-dropdown border-0 rounded-0 p-0 m-0">
                  <li
                    v-for="(lang, idx) in languages"
                    :key="`lang-${idx}`"
                    class="d-flex justify-content-between align-items-center header-dropdown__item"
                    @click="selectLanguage(lang),hideDeskLng()"
                  >
                    <span class="d-flex align-items-center">
                      <span class="text-uppercase">{{ lang.name }}</span>
                    </span>
                  </li>
                </ul>
              </app-transition-expat>
            </li>
            <li class="nav-item dropdown d-none d-md-flex" v-click-outside="hideDeskCurrency">
              <a
                id="carrency"
                class="nav-link p-0 d-flex align-items-center dropdown-toggle text-uppercase text-white"
                href="#"
                @click="isCurrency = !isCurrency"
              >
                {{ activeCurrencyGetters.label }}
              </a>
              <app-transition-expat>
                <ul v-show="isCurrency" class="position-absolute header-dropdown border-0 rounded-0 p-0 m-0">
                  <li
                    v-for="(currency, index) of currencys"
                    :key="index"
                    class="d-flex justify-content-between align-items-center header-dropdown__item"
                    @click="changeActiveCurrency(currency),hideDeskCurrency()"
                  >
                    <span class="d-flex align-items-center">
                      <span class="text-uppercase"> {{ currency.label }} </span>
                    </span>
                  </li>
                </ul>
              </app-transition-expat>
            </li>
          </ul>
        </div>
        <app-header-second />
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import HeaderMixin from '~/core/mixins/header'
import goToAdminPanel from '~/core/mixins/goToAdminPanel'

import AppHeaderSecond from '~/components/ui/app-header-second.vue'
import Cart from '~/core/mixins/cart'

export default {
  name: 'AppHeader',

  components: {
    AppHeaderSecond
  },
  mixins: [Cart, HeaderMixin, goToAdminPanel],
  data () {
    return {
      isCurrency: false,
      isLng: false,
      isLoadJobs: false,
      wordChange: true,
      words: [
        'Զբաղվու՞մ եք ոսկեգործությամբ լրացրեք և ստացեք 2 զարդի անվճար ստուգում',
        'Փնտրու՞մ եք աշխատանք: Տեսնել ավելին'
      ],
      wordLinks: [
        '/forms/dlr',
        '/jobs'
      ],
      currentWord: '',
      currentIndex: 0,
      offset: 0,
      forwards: true,
      skipCount: 0,
      skipDelay: 30,
      speed: 60,
      intervalId: null
    }
  },
  computed: {
    ...mapState({
      cartLists: state => state.cart.cartListInfo.catalog || [],
      totalCount: state => state.cart.cartListInfo.total_count || 0,
      totalPrice: state => state.cart.cartListInfo.total_price || 0,
      totalWishlist: state => state.favorite.listInfo.total_count || 0,
      isJobs: state => state.ui.isJobs
    }),
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    },
    currentUserFullName () {
      const [name] = this.currentUser.lastname
      return `${this.currentUser.firstname}${!name ? '.' : ` ${name}.`}`
    }
  },
  mounted () {
    // const cookJob = await this.$cookies.get('isJobs')
    this.scrollToTop()
    // if (cookJob) {
    //   this.closeIsJobs()
    // }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    ...mapMutations({
      toggleMenu: 'ui/toggleMenu'
    }),
    switchRouteHandler () {
      if (!this.switchRoute) {
        this.$cookies.set('siteMode', 'silver')
        this.$store.commit('siteMode/setMode', 'silver')
      } else {
        this.$cookies.set('siteMode', 'gold')
        this.$store.commit('siteMode/setMode', 'gold')
      }
    },
    closeIsJobs () {
      this.$store.commit('ui/setIsJobs', 1)
      // this.$cookies.set('isJobs', 1)
    },
    hideDeskCurrency () {
      this.isCurrency = false
    },
    hideDeskLng () {
      this.isLng = false
    },
    openSearchModal () {
      if (this.$store.state.ui.isModal !== 'search') {
        this.$store.commit('ui/setModal', 'search')
      } else {
        this.$store.commit('ui/setModal')
      }
    },
    openAuthModal () {
      if (this.currentUser) {
        return
      }
      this.$store.commit('ui/setModal', 'auth')
    },
    goToWishlist () {
      if (this.currentUser) {
        return this.$router.push({ path: this.localePath('/wishlist') })
      }
      this.$store.commit('ui/setModal', 'auth')
    },
    goToCheckout () {
      if (!this.totalCount) {
        return
      }
      if (this.currentUser) {
        return this.$router.push({ path: this.localePath('/cart/checkout') })
      }
      const closeDrawerMenu = document.getElementById('close-drawer-menu')
      if (closeDrawerMenu) {
        closeDrawerMenu.click()
      }
      this.$store.commit('ui/setModal', 'auth')
    },
    goToCart () {
      this.$router.push({ path: this.localePath('/cart') })
    },
    async navigateToMain () {
      await this.$cookies.remove('productId')
      await this.$cookies.remove('sellerId')
      this.$router.push(this.localePath('/'))
      this.scrollToTop()
    },
    wordflick () {
      this.intervalId = setInterval(() => {
        let part = ''
        if (this.forwards) {
          if (this.offset >= this.words[this.currentIndex].length) {
            this.wordChange = false
            this.skipCount++
            if (this.skipCount === this.skipDelay) {
              this.forwards = false
              this.skipCount = 0
            }
          }
        }
        if (!this.forwards && this.offset === 0) {
          this.forwards = true
          this.wordChange = true
          this.currentIndex++
          this.offset = 0
          if (this.currentIndex >= this.words.length) {
            this.currentIndex = 0
          }
        }
        if (this.wordChange) {
          part = this.words[this.currentIndex].substr(0, this.offset)
        } else {
          part = this.words[this.currentIndex]
        }
        if (this.skipCount === 0) {
          if (this.forwards) {
            this.offset++
          } else {
            this.offset--
          }
        }
        this.currentWord = part
      }, this.speed)
    },
    renderColoredWords (word) {
      const coloredWords = ['Տեսնել', 'լրացրեք']
      coloredWords.forEach((coloredWord) => {
        const regex = new RegExp(coloredWord, 'g')
        word = word.replace(regex, `<span class="banner_span" style="text-decoration: underline">${coloredWord}</span>`)
      })
      return word
    },
    renderUrl () {
      return this.wordLinks[this.currentIndex]
    },
    pauseAnimation () {
      clearInterval(this.intervalId)
    },
    resumeAnimation () {
      this.wordflick()
    }
  },
  created () {
    if (this.$store.state.siteMode.sitemode === '' || this.$store.state.siteMode.sitemode === 'undefined') {
      this.$store.commit('siteMode/setMode', this.$cookies.get('siteMode'))
    }
    this.wordflick()
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  }
}
</script>

<style>
@import '~/assets/css/core/header.css';
@import '~/assets/css/pages/search.css';
</style>

<style scoped>
@media(max-width: 500px) {
  .navbar-brand__image.logo-heigth {
    width: 100%;
    height: auto !important;
  }
}
.slider:before {
  box-shadow: 4px 2px 5px 0px rgba(0, 0, 0, 0.75);
  left: 2px;
  bottom: 2px;
}
.switch__input + .slider {
  background-color: #fff;
}
.custom-dropdown-item {
  padding: 15px;
  transition: all ease 1s;
}

.word .banner_span:hover {
  color: rgb(219, 173, 91) !important;
}

.banner_span{
  -webkit-text-decoration: underline;
  text-decoration: underline;
  font-style: italic;
  letter-spacing: 1px;
  white-space: nowrap;
}
.custom-dropdown-item:hover {
  opacity: 0.7;
}

.menyu-mobile-icon {
  width: 20px;
  height: 20px;
}

.notification-active {
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background: yellow;
  border-radius: 10px;
  background: #eb001b;
  width: 14px;
  height: 14px;
  font-size: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: 4px;
  letter-spacing: 0.333px;
  text-transform: uppercase;
}

.header {
  background: var(--white);
  transition: .25s;
}

.header .navbar-nav .nav-item:last-child .nav-link img {
  margin: 0 0 0 8px;
}

.aside-item {
  margin-bottom: 20px;
}

.navbar-toggler {
  width: 20px;
}

.logo-heigth {
  height: 31.191px !important;
}

@media all and (min-width: 766.9px) {
  .logo-heigth {
    height: 43px !important;
  }

  .header .header-first .nav-item:not(:last-child) {
    margin-right: 18px;
  }

  .header .slider {
    height: 18px;
    width: 40px;
  }

  .header .slider:before {
    height: 14px;
    width: 14px;
  }

  .header-second .secondary-btn {
    font-size: 16px;
  }

  .notification-active {
    width: 12px;
    height: 12px;
    top: -2px;
    right: -4px;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 4px;
    letter-spacing: 0.333px;
  }

  .navbar-brand {
    margin: auto;
  }
}

@media all and (max-width: 566.9px) {
  .header>>>.app-basket-right+.modal-backdrop.show {
    pointer-events: none !important;
    opacity: 0 !important;
  }
}

.isJobs {
  background-color: var(--seccondary-color);
  height: 36px;
  width: 100%;
  display: flex;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 14px;
}

.isJobs span {
  cursor: pointer;
  text-align: center;
}
.isJobs span span{
  -webkit-text-decoration: underline !important;
  text-decoration: underline  !important;
  font-style: italic !important;
  letter-spacing: 1px !important;
  white-space: nowrap !important;
}

@media all and (max-width: 845px) {
  .isJobs :is(span, i) {
    font-size: 12px;
  }
}

@media all and (max-width: 360px) {
  .isJobs :is(span, i) {
    font-size: 10px;
  }
}

.isJobs i {
  text-decoration: underline;
  white-space: nowrap;
}

.isJobs button {
  position: absolute;
  right: 8px;
}
.isJobs span.word span.banner_span:hover {
  color: #c8ae5b !important
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__input.checked + .slider:before {
  transform: translateX(20px);
}
.switch__label,
.switch__checked {
  font-size: 14px;
  margin: 0;
}

@media all and (max-width: 766.9px) {
  .switch__label,
  .switch__checked {
    font-size: 16px;
    line-height: 1;
  }
  .switch__input.checked + .slider:before {
    transform: translateX(14px);
  }
}
</style>
